<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <span class="headline">{{ edit ? 'Edit':'Add' }} Choice</span>
        <v-spacer/>
        <v-btn @click="$emit('close')" icon color="red">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>
    </v-card-title>
    <v-divider class="mb-4"/>
    <v-card-text>
      <form @submit.prevent="saveOrUpdate()">
        <v-row no-gutters class="mb-n4">
          <v-col
             
              cols="12"
              sm="12"
            >
            <span  class="err-msg" v-if="v$.title.$error">{{ v$.title.$errors[0].$message }}</span>
            <v-text-field class="mr-2" outlined  label="Choice"  v-model="state.title"></v-text-field>
          </v-col> 
          
        </v-row>
        <v-row justify="end" no-gutters>
          <v-btn type="submit" :loading="loading"  width="128" elevation="0"  height="56" color="primary">Save</v-btn>
        </v-row>
    </form>
    </v-card-text>
  </v-card>
</template>

<script>
import {authClient} from "@/plugins/http";
import useValidate from '@vuelidate/core'
import { required,numeric, maxLength } from '@vuelidate/validators'
import { reactive, computed } from "vue";


export default {
  setup(){
    const state = reactive({
      title: "",
    })

    const rules = computed(()=>{
      return { 
          title: { required ,maxLength:maxLength(300)}
        }
    })

    
    const v$ =  useValidate(rules, state)

    return {
      v$,
      state
    }
  },
  name: "AddDietFormAns",
  props: {
    q_id: Number,
    edit: Boolean,
    story: Object,
    domain:String,
    notes_obj: Object
  },
  data: () => ({
    loading: false,
  }),
  methods:{
    submitForm(){
      this.v$.$validate()
      if (!this.v$.$error) {
        this.loading = true;

        authClient.post('/diet-form/ans/add',{
          "QId" : this.q_id,
          "title" : this.state.title
        })
        .then((response) => {
            this.$emit('close');
            this.loading = false;
            // this.loadData()
            let response_data = response.data[0]
            if(response_data.status){
              this.$toast.success({
                title: 'Ok',
                message: response_data.message,
            });
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: response_data.message,
            });
            }
          }).catch((error) => {
            console.log(error)
            this.loading = false;
            this.$toast.error({
                title: 'error',
                message: 'error',
            });
          });
    
        }else{
          this.loading = false
        }
    },
    updateForm(){
      this.v$.$validate()
      if (!this.v$.$error) {
        this.loading = true;
        authClient.post('/diet-form/ans/update',{
          "title" : this.state.title,
          "QId" : this.notes_obj.id
        })
        .then((response) => {
            this.$emit('close');
            this.loading = false;
            // this.loadData()
            let response_data = response.data[0]
            if(response_data.status){
              this.$toast.success({
                title: 'Ok',
                message: response_data.message,
            });
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: response_data.message,
            });
            }
          }).catch((error) => {
            console.log(error)
            this.loading = false;
            this.$toast.error({
                title: 'error',
                message: 'error',
            });
          });
    
        }else{
          this.loading = false
        }
    },
    saveOrUpdate(){
      this.loading = true;
      if(this.edit) {
        this.updateForm();
      } else {
        this.submitForm();
      }
    }
  },
  mounted() {
    if(this.edit){
      this.state.title = this.notes_obj.title;
      this.state.description = this.notes_obj.description;
    }
  }
}
</script>


<style scoped>

.img-properties {
    width: 100%;
    max-width: 100%;
    height: auto;
    position: relative;
    z-index: 0;
    border-radius: 0.25rem;
    margin: 0 auto;
    display: block;
    max-height: 400px;
    margin-top: 10px;
  }

  .img-container-1{
    position: relative;
    width: 100%;
  }

  .img-container-2{
    position: relative;
    width: 100%;
  }

  .main-container{
    width: 100%;
  }
  .main-img-container{
    background-color: #BBDEFB;
    border-radius: 10px;
    min-height: 15vw;
  }

  .img-container{
    width: 100%;
    display: flex;
    
  }

  .upload-lable{
    position: absolute;
    top: 1rem; /* You can adjust the top margin as needed */
    margin-left: 1rem; /* You can adjust the left margin as needed */
    z-index: 1;
  }

  .err-msg{
    color: red;
  }

  .loading-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999999;
  }

  .d-none{
    display: none;
  }


</style>
