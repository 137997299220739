<template>
  <v-container>

    <v-row no-gutters>
      <v-text-field v-model="search" outlined rounded label="Search Service..."></v-text-field>
      <v-btn @click="addNew = true;isEdit=false;" rounded dark color="black" height="56" elevation="0" class="ml-2">
        <v-icon class="mr-2">mdi-plus</v-icon>
        Add New
      </v-btn>
    </v-row>

    <v-card outlined :loading="loading">
      <v-data-table
          :search="search"
          :headers="headers"
          :items="products">

        <template v-slot:item.image_path="{ item }">
          <v-avatar class="ma-2">
            <v-img :src="$store.state.s3ResourcesBaseUrl + item.image_path"></v-img>
          </v-avatar>
        </template>

        <template v-slot:item.start_time="{ item }">
          <span v-if="item.start_time">
            {{ new Intl.DateTimeFormat("en-GB", options).format(new Date(item.start_time + "Z")).split(', ')[0] }}
            {{ new Intl.DateTimeFormat("en-GB", options).format(new Date(item.start_time + "Z")).split(', ')[1] }}
          </span>
          <span v-else>
            N/A
          </span>
        </template>


        <template v-slot:item.end_time="{ item }">
          <span v-if="item.end_time">
            {{  new Intl.DateTimeFormat("en-GB", options).format(new Date(item.end_time + "Z")).split(', ')[0] }}
          {{  new Intl.DateTimeFormat("en-GB", options).format(new Date(item.end_time + "Z")).split(', ')[1] }}
          </span>
          <span v-else>
            N/A
          </span>
        </template>


        <template v-slot:item.description="{ item }">
          <v-btn outlined rounded @click="descriptionViewDialog = true; selectedDescription = item.description">
            View
          </v-btn>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-btn icon @click="editProduct(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
          <v-icon @click="showDeleteConfirmation(item, deleteProduct)" color="error">mdi-delete</v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog max-width="768" persistent v-if="descriptionViewDialog" v-model="descriptionViewDialog">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <span class="headline">Description</span>
            <v-spacer/>
            <v-btn @click="descriptionViewDialog = false; selectedDescription=''" icon color="red">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>
        <v-divider class="mb-4"/>
        <v-card-text>
          {{ selectedDescription }}
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-bottom-sheet persistent="false" scrollable  v-if="addNew" v-model="addNew" inset hide-overlay >
      <v-card class="rounded-t-lg rounded-b-0">
        <v-card-title>
          <v-row no-gutters>
            {{ isEdit ? 'Edit' : 'Add' }} Service
            <v-spacer/>
            <v-btn color="red" icon @click="close()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-form v-if="!isEdit" ref="form" v-model="valid">
            <v-select  :items="exGymList" v-model="data.gym_id" class="rounded-lg" item-text="gym_name" item-value="user_id"  :rules="[rules.required]" outlined label="Select Gym"/>
            <v-text-field v-model="data.name" outlined label="Name" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.description" outlined label="Description" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.price" type="number" min="0" outlined label="Price" :rules="[rules.required]"></v-text-field>
            <v-file-input v-model="image" prepend-icon="" prepend-inner-icon="mdi-image" outlined label="Image" :rules="[rules.required]"></v-file-input>
            <v-row class="mt-2" no-gutters>
              <v-col
              class="ml-n4"
                cols="12"
                sm="6"
                      >
              <template >
                <div class="mr-0">
                        <v-menu
                          ref="start_date_menu"
                          v-model="start_date_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="start_date"
                              label="Start date"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="start_date"
                            :active-picker.sync="activePicker_2"
                            min="1950-01-01"
                            @change="save_s"
                          ></v-date-picker>
                        </v-menu>
                      </div>
              </template>
              </v-col>
              
              <v-col
                class="ml-4"
                cols="12"
                sm="6"
                      >
                      <template>
                        <v-dialog
                          ref="start_modal"
                          v-model="start_modal"
                          :return-value.sync="start_time"
                          persistent
                          width="290px"
                          class="mr-0"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="start_time"
                              label="Start time"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            @input="emitValue"
                              v-if="start_modal"
                              v-model="start_time"
                              full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="start_modal = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.start_modal.save( `${start_time}:00`)"
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </template>
              </v-col>
            </v-row>
            <v-row class="mt-2" no-gutters>
              <v-col
              class="ml-n4"
                cols="12"
                sm="6"
                      >
              <template >
                <div class="mr-0">
                        <v-menu
                          ref="end_date_menu"
                          v-model="end_date_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="end_date"
                              label="End date"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="end_date"
                            :active-picker.sync="activePicker_1"
                            min="1950-01-01"
                            @change="save_e"
                          ></v-date-picker>
                        </v-menu>
                      </div>
              </template>
              </v-col>
              
              <v-col
                class="ml-4"
                cols="12"
                sm="6"
                      >
                      <template>
                        <v-dialog
                          ref="end_modal"
                          v-model="end_modal"
                          :return-value.sync="end_time"
                          persistent
                          width="290px"
                          class="mr-0"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="end_time"
                              label="End time"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            @input="emitValue"
                              v-if="end_modal"
                              v-model="end_time"
                              full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="end_modal = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.end_modal.save( `${end_time}:00`)"
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </template>
              </v-col>
            </v-row>
          </v-form>

          <v-form v-if="isEdit" ref="formEdit" v-model="validEdit">
            <v-select  :items="exGymList" v-model="data.gym_id" class="rounded-lg" item-text="gym_name" item-value="user_id"  :rules="[rules.required]" outlined label="Select Gym"/>
            <v-text-field v-model="data.name" outlined label="Name" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.description" outlined label="Description" :rules="[rules.required]"></v-text-field>
            <v-text-field v-model="data.price" type="number" min="0" outlined label="Price" :rules="[rules.required]"></v-text-field>
            <v-file-input v-model="image" prepend-icon="" prepend-inner-icon="mdi-image" outlined label="Image"></v-file-input>
            <v-row class="mt-2" no-gutters>
              <v-col
              class="ml-n4"
                cols="12"
                sm="6"
                      >
              <template >
                <div class="mr-0">
                        <v-menu
                          ref="start_date_menu"
                          v-model="start_date_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="start_date"
                              label="Start date"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="start_date"
                            :active-picker.sync="activePicker_2"
                            min="1950-01-01"
                            @change="save_s"
                          ></v-date-picker>
                        </v-menu>
                      </div>
              </template>
              </v-col>
              
              <v-col
                class="ml-4"
                cols="12"
                sm="6"
                      >
                      <template>
                        <v-dialog
                          ref="start_modal"
                          v-model="start_modal"
                          :return-value.sync="start_time"
                          persistent
                          width="290px"
                          class="mr-0"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="start_time"
                              label="Start time"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            @input="emitValue"
                              v-if="start_modal"
                              v-model="start_time"
                              full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="start_modal = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.start_modal.save( `${start_time}:00`)"
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </template>
              </v-col>
            </v-row>
            <v-row class="mt-2" no-gutters>
              <v-col
              class="ml-n4"
                cols="12"
                sm="6"
                      >
              <template >
                <div class="mr-0">
                        <v-menu
                          ref="end_date_menu"
                          v-model="end_date_menu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="end_date"
                              label="End date"
                              prepend-icon="mdi-calendar"
                              readonly
                              outlined
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="end_date"
                            :active-picker.sync="activePicker_1"
                            min="1950-01-01"
                            @change="save_e"
                          ></v-date-picker>
                        </v-menu>
                      </div>
              </template>
              </v-col>
              
              <v-col
                class="ml-4"
                cols="12"
                sm="6"
                      >
                      <template>
                        <v-dialog
                          ref="end_modal"
                          v-model="end_modal"
                          :return-value.sync="end_time"
                          persistent
                          width="290px"
                          class="mr-0"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="end_time"
                              label="End time"
                              prepend-icon="mdi-clock-time-four-outline"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-time-picker
                            @input="emitValue"
                              v-if="end_modal"
                              v-model="end_time"
                              full-width
                          >
                            <v-spacer></v-spacer>
                            <v-btn
                              text
                              color="primary"
                              @click="end_modal = false"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              text
                              color="primary"
                              @click="$refs.end_modal.save( `${end_time}:00`)"
                            >
                              OK
                            </v-btn>
                          </v-time-picker>
                        </v-dialog>
                      </template>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-card-actions style="background: #1976d2">
          <v-spacer/>
          <v-btn v-if="!isEdit" :loading="loading" @click="addNewProduct" large color="white">
            <v-icon class="mr-2">mdi-check</v-icon>
            Save
          </v-btn>
          <v-btn v-if="isEdit" :loading="loading" @click="saveEdited" large color="white">
            <v-icon class="mr-2">mdi-check</v-icon>
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-bottom-sheet>
  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";

export default {
  name: "PendingGymServices",
  data: () => ({
    rules: {
      required: value => !!value || 'Required.',
    },

    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone, 

    activePicker_1:null,
    end_date:null,
    end_date_menu:false,

    end_modal:null,
    end_time:null,

    activePicker_2:null,
    start_date:null,
    start_date_menu:false,

    start_modal:null,
    start_time:null,

    data: {
      id: null,
      gym_id: null,
      name: '',
      description: '',
      price: 0,
      image_path: '',
    },
    descriptionViewDialog:false,
    valid: false,
    validEdit: false,
    image: null,
    search: '',
    loading: true,
    addNew: false,
    isEdit: false,
    options : {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
        },
    exGymList:[],
    headers: [
      {text: "ID", value: "id"},
      {text: "", value: "image_path", sortable: false},
      {text: "Name", value: "name"},
      {text: "Gym", value: "gym_name"},
      {text: "Price", value: "price"},
      {text: "Description", value: "description"},
      {text: "Start", value: "start_time"},
      {text: "End", value: "end_time"},
      {text: "Actions", value: 'actions', sortable: false}
    ],
    products: [],
    categories: [],
  }),
  methods: {
      showDeleteConfirmation(item, callback) {
        this.$toast.question({
          timeout: 10000, // The time in milliseconds the toast will be displayed
          close: false, // Whether to show the close button
          overlay: true, // Whether to display an overlay behind the toast
          toastOnce: true, // Whether to show the toast only once
          id: 'deleteToast',
          zindex: 999,
          title: 'Confirmation',
          message: 'Are you sure you want to delete?',
          position: 'center',
          buttons: [
            ['<button><b>YES</b></button>', function (instance, toast) {
              callback(item)
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }, true],
            ['<button>NO</button>', function (instance, toast) {
              instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
            }],
          ]
        });
      },
      deleteProduct(item) {
        authClient.post('gym-services/delete',{
          service_id: item.id
          }).then((response) => {
            let data = response.data[0]
            if(data.status){
              this.$toast.success({
                title: 'Ok',
                message: data.message,
              });
              this.getServices()

            authClient.post('/users/file-uploads/delete-file',{
            filePath: item.image_path
            }).then((response) => {
            }).catch((error) => {
              console.log(error);
            });
            
            }else{
              this.loading = false;
              this.$toast.error({
                title: 'error',
                message: data.message,
            });
          }
            
          }).catch((error) => {
            console.log(error);
          });
      },
      resetProduct(){
        this.data = {
          id: null,
          name: '',
          description: '',
          code: '',
          price: 0,
          quantity: 0,
          image_path: '',
          CategoryId: null,
        };
        this.image = null;
        this.start_date = null;
        this.end_date = null;
        this.start_time =null;
        this.end_time = null;
      },
      editProduct(item){

        

        const utcDate = new Date(item.start_time + "Z");
        const [datePart, s_time] = new Intl.DateTimeFormat("en-GB", this.options).format(utcDate).split(', ');
        const s_date = `${datePart.split('/').reverse().join('-')}`;

        const e_utcDate = new Date(item.end_time + "Z");
        const [e_datePart, e_time] = new Intl.DateTimeFormat("en-GB", this.options).format(e_utcDate).split(', ');
        const e_date = `${e_datePart.split('/').reverse().join('-')}`;

        this.isEdit = true;
        this.addNew = true;
        this.data = item;
        this.start_date = s_date;
        this.end_date = e_date;
        this.start_time =s_time;
        this.end_time = e_time;
      },
      saveEdited(){
        const startTime = new Date(new Date(`${this.start_date}T${this.start_time}`).toLocaleString("en-US", { timeZone: this.timezone })).toISOString().slice(0, 19).replace('T', ' ');
        const endTime = new Date(new Date(`${this.end_date}T${this.end_time}`).toLocaleString("en-US", { timeZone: this.timezone })).toISOString().slice(0, 19).replace('T', ' ');;
        
        this.data["start_time"] = startTime;
        this.data["end_time"] = endTime;
        this.$refs.formEdit.validate();
        if (this.validEdit) {
          this.loading = true;
          if(this.image === null){
            authClient.post('/gym-services/update', this.data).then(res => {
              console.log(res);
              this.loading = false;
              this.resetProduct();
              this.close();
            }).catch(err => {
              this.loading = false;
              console.log(err);
              window.alert('Something went wrong. Please try again later.');
            });
          } else {
            authClient.post('/users/file-uploads/delete-file', {
              filePath: this.data.image_path
            }).then(rs => {
              console.log(rs);
              let formData = new FormData();
              formData.append('image', this.image);
              authClient.post('/users/file-uploads/gymService', formData).then(res => {
                if(res.status === 200){
                  let mData = this.data;
                  console.log(res.data);
                  mData.image_path = res.data.image_path;
                  authClient.post('/gym-services/update', mData
                  ).then(res2 => {
                    if(res2.status === 200){
                      this.loading = false;
                      this.close();
                    }
                  }).catch(err => {
                    this.loading = false;
                    console.log(err);
                    window.alert('Something went wrong. Please try again later.');
                  });
                }
              }).catch(err => {
                this.loading = false;
                window.alert('Error uploading image');
              });
            });


          }


        }
      },
      addNewProduct() {
        console.log('Adding new product...');
        this.$refs.form.validate();
        if (this.valid) {
          this.loading = true;
          let formData = new FormData();
          formData.append('image', this.image);
          authClient.post('/users/file-uploads/gymService', formData).then(res => {
            if(res.status === 200){


              const startTime = new Date(new Date(`${this.start_date}T${this.start_time}`).toLocaleString("en-US", { timeZone: this.timezone })).toISOString().slice(0, 19).replace('T', ' ');
              const endTime = new Date(new Date(`${this.end_date}T${this.end_time}`).toLocaleString("en-US", { timeZone: this.timezone })).toISOString().slice(0, 19).replace('T', ' ');;
              
              let mData = this.data;
              console.log(res.data);
              mData.image_path = res.data.image_path;
              console.log(mData);
              authClient.post('/gym-services/add',{
                ...mData,
                "start_time" : startTime,
                "end_time" : endTime
              }).then(res => {
                
                let response_data = res.data[0]
                if(response_data.status){
                  this.loading = false;
                  this.close();
                  this.data = {
                    id: null,
                    name: '',
                  };
                  this.$toast.success({
                    title: 'Ok',
                    message: response_data.message,
                });
                }else{
                  authClient.post('/users/file-uploads/delete-file', {
                        filePath: mData.image_path
                  })
                  this.loading = false;
                  this.$toast.error({
                    title: 'error',
                    message: response_data.message,
                });
                }
          }).catch(err => {
            this.loading = false;
            this.$toast.error({
                    title: 'error',
                    message: 'error',
                });
          });
            }
          }).catch(err => {
            this.loading = false;
            window.alert('Error uploading image');
          })
        }
      },
      close() {
        this.addNew = false;
        this.getServices();
        this.resetProduct();
      },
      save_s (date) {
      this.$refs.start_date_menu.save(date)
      },
      save_e (date) {
      this.$refs.end_date_menu.save(date)
      },
      emitValue() {
        const [hours, minutes] = this.time.split(':');
        this.$emit('value-emitted', `${hours}:${minutes}:00`);
      },
      getServices() {
        this.loading = true;
        this.products = [];

        let sk = 'ALL';
        if (this.search !== '') {
          sk = this.search;
        }
        authClient.get('/gym-services/pending/approved-list')
            .then(response => {
              this.loading = false;
              let data = response.data[0]
              if(data.status){
                this.products = data.data.result;
                this.loading = false;
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: data.message,
              });
            }
            })
            .catch(error => {
              console.log(error);
              this.loading = false;
            })
      },
      getExclusiveGyms() {
        this.loading = true;
        this.exGymList = [];

        let sk = 'ALL';
        if (this.search !== '') {
          sk = this.search;
        }
        authClient.post('/gyms/exclusive/search/web')
            .then(response => {
              this.loading = false;
              let data = response;
              if(data.status){
                this.exGymList = data.data;
                this.loading = false;
              }else{
                this.loading = false;
                this.$toast.error({
                  title: 'error',
                  message: data.message,
              });
            }
            })
            .catch(error => {
              console.log(error);
              this.loading = false;
            })
      },
  },
  mounted() {
    this.loading = false;
    this.getServices();
    this.getExclusiveGyms()
  }
}
</script>

<style scoped>

</style>
