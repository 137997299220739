<template>
    <div class="under-maintenance">
      <div class="content">
        <h1>App Notification Manager'll be back soon!</h1>
        <p>
          Sorry for the inconvenience. We're performing some maintenance at the moment.
          We'll be back online shortly!
        </p>
        <p>- The Team</p>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "MaintainceView",
  };
  </script>
  
  <style scoped>
  .under-maintenance {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;
    text-align: center;
    font-family: 'Arial', sans-serif;
  }
  
  .content {
    max-width: 600px;
    padding: 20px;
    background: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  h1 {
    font-size: 2.5rem;
    color: #333;
  }
  
  p {
    font-size: 1rem;
    color: #555;
    margin: 10px 0;
  }
  </style>
  