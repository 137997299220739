import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Vue2Editor from "vue2-editor";
import iziToast from 'izitoast';
import 'izitoast/dist/css/iziToast.css';
import SimpleVueValidation from 'simple-vue-validator';

Vue.use(SimpleVueValidation);
Vue.use(Vue2Editor);

Vue.config.productionTip = false;
Vue.prototype.$toast = iziToast;

Vue.mixin({
  methods: {
    covert_localtime(date_time, date = false) {

      if(date) {
        return new Date(date_time).toLocaleDateString('en-GB', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
      });
      }

      return new Date(date_time).toLocaleString('en-GB', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        })
    },
    getDifferenceInHours(date1, date2) {
      // Convert date strings to Date objects
      const d1 = new Date(date1);
      const d2 = new Date(date2);
    
      // Calculate the difference in milliseconds
      const diffInMs = Math.abs(d2 - d1);
    
      // Convert milliseconds to hours (1 hour = 3600000 milliseconds)
      const diffInHours = diffInMs / (1000 * 60 * 60);
    
      return diffInHours;
    }
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
