import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,[_c(VCardTitle,[_c(VRow,{attrs:{"no-gutters":""}},[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.edit ? 'Edit':'Add')+" Quesion")]),_c(VSpacer),_c(VBtn,{attrs:{"icon":"","color":"red"},on:{"click":function($event){return _vm.$emit('close')}}},[_c(VIcon,[_vm._v("mdi-close")])],1)],1)],1),_c(VDivider,{staticClass:"mb-4"}),_c(VCardText,[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.saveOrUpdate()}}},[_c(VRow,{staticClass:"mb-n4",attrs:{"no-gutters":""}},[_c(VCol,{attrs:{"cols":"12","sm":"12"}},[(_vm.v$.title.$error)?_c('span',{staticClass:"err-msg"},[_vm._v(_vm._s(_vm.v$.title.$errors[0].$message))]):_vm._e(),_c(VTextField,{staticClass:"mr-2",attrs:{"outlined":"","label":"Question"},model:{value:(_vm.state.title),callback:function ($$v) {_vm.$set(_vm.state, "title", $$v)},expression:"state.title"}})],1)],1),_c(VRow,{attrs:{"justify":"end","no-gutters":""}},[_c(VBtn,{attrs:{"type":"submit","loading":_vm.loading,"width":"128","elevation":"0","height":"56","color":"primary"}},[_vm._v("Save")])],1)],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }