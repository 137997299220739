<template>
  <v-container>
    <v-row  class="mb-4 my-1">
      <v-card outlined hover ripple dark class="warning mr-4 mb-3 mb-md-0" min-width="180" width="auto">
      <v-card-subtitle class="pb-0">Total Amount</v-card-subtitle>
      <v-card-title class="pt-0 text-h4">{{ Total_amount.toFixed(2) }}</v-card-title>
    </v-card>
    </v-row>
    <v-row gutters>
      <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
      <div>
    <v-text-field v-model="search" outlined class="rounded-lg" label="Search Members..." append-icon="mdi-magnify" @keyup.enter="getMembers()" @click:append="getMembers()"></v-text-field>
  </div>
      </v-col>
      <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
        <v-dialog
          ref="dialog"
          v-model="modal"
          :return-value.sync="date"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              class="rounded-lg"
              outlined
              readonly
              v-model="dateRangeText"
              label="Date Range"
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dates"
            range
          >
            <v-spacer></v-spacer>
            <v-btn
              text
              color="primary"
              @click="dates= []"
            >
              Reset
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="modal = false"
            >
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(date);getMembers()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <v-col
            cols="12"
            md="3"
            lg="2"
      >
            <v-btn
            @click="getMembers(1)"
            :style="{ width: '100%' }"
              x-large
              color="warning"
              dark
            :loading="loading"
            >
            <v-icon
              left
              dark
            >
            mdi-tray-arrow-down
            </v-icon>
              Export Pdf
            </v-btn>
      </v-col>
      <v-col
            cols="12"
            md="3"
            lg="2"
      >
            <v-btn
            @click="downloadCsv()"
            :loading="loading"
            :style="{ width: '100%' }"
              x-large
              color="warning"
              dark
            >
            <v-icon
              left
              dark
            >
            mdi-tray-arrow-down
            </v-icon>
              Export Csv
            </v-btn>
      </v-col>
    </v-row>
    <v-row gutters class="mt-0">
      <v-col
            class="pb-0"
            cols="12"
            md="6"
            lg="4"
      >
      <v-select @change="getMembers()" :items="[ {'name':'ALL', 'id': ''} , ...services]" v-model="service_id" class="rounded-lg" item-text="name" item-value="id"  outlined label="Select Service"/>
    </v-col>
    </v-row>

    <v-card outlined :loading="loading" class="mt-5">
      <v-data-table
          id="dataTabel"
          :headers="headers"
          :items="members">
        <template v-slot:item.membership="{ item }">
          <v-chip dark :color="hasActiveSub(item) ? '#ffa200':''">{{ hasActiveSub(item) ? 'PRO' : 'Member' }}</v-chip>
        </template>

        <template v-slot:item.user.avatar_url="{ item }">
          <v-avatar class="ma-2">
            <v-img :src="$store.state.s3BaseUrl + item.user.avatar_url"></v-img>
          </v-avatar>
        </template>

        <template v-slot:item.user.status="{ item }">
            <v-chip
              class="ma-2"
              :color=" item.user.status == 1 ? 'success': 'warning'"
            >
            {{ item.user.status == 1 ? 'Active' : 'Disabled' }}
            </v-chip>
          </template>

        <template v-slot:item.subscription="{ item }">
             {{  ValidDate(item)  }}
        </template>

        <template v-slot:item.created_at="{ item }">
            {{
            covert_localtime(item.created_at)  
              }}
        </template>

        <template v-slot:item.booking.start_time="{ item }">
            {{
              item.booking ? covert_localtime(item.booking.start_time) : ""  
              }}
        </template>

        <template v-slot:item.booking.end_time="{ item }">
            {{
            item.booking ? covert_localtime(item.booking.end_time)  : ""
              }}
        </template>

        <template v-slot:item.difference="{ item }">
            {{
            item.booking ? getDifferenceInHours(item.booking.end_time,item.booking.start_time)  : ""
              }}
        </template>

        <template v-slot:item.payment_type="{ item }">
          {{ item.payment_type == '1' ? 'Card' : 'E-Gift' }}
        </template>

        <template v-slot:item.actions="{ item }">

          <v-btn @click="statusChange(item.user.id, item.user.status == 1? 2 : 1)" icon>
            <v-icon :color=" item.user.status == 2 ? 'success': 'warning'"> {{ item.user.status == 1 ? 'mdi-cancel' : 'mdi-check' }}</v-icon>
          </v-btn>
          
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn @click="viewDetails(item)" v-bind="attrs"
                     v-on="on" icon>
                <v-icon>mdi-dots-horizontal</v-icon>
              </v-btn>

            </template>
            <span>More Info</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

  </v-container>
</template>

<script>
import {authClient} from "@/plugins/http";
import Papa from 'papaparse';

export default {
  name: "GymServiceSummery",
  props: {
      gym_id: Number
  },
  data: () => ({
    search: '',
    members: [],
    date:null,
    gym_id:undefined,
    service_id:'',
    menu: false,
    Total_amount:0,
    modal: false,
    menu2: false,
    memberShip:'0',
    dates: [],
    services:[],
    loading: true,
    proMemberCount:0,
    MemberCount:0,
    active_member_count:0,
    disabled_member_count:0,
    headers: [
      {text: "User ID", value: "trainer.id"},
      {text: "Transaction Date & Time", value: "created_at"},
      {text: "Start Date & Time", value: "booking.start_time"},
      {text: "End Date & Time", value: "booking.end_time"},
      {text: "Hours", value: "difference"},
      {text: "Name", value: "trainer.name"},
      {text: "Email", value: "trainer.email"},
      {text: "Phone", value: "trainer.phone"},
      {text: "Facility", value: "gym.gym_name"},
      {text: "Service", value: "service.name"},
      {text: "Discount Type", value: "promo_code"},
      {text: "Amount", value: 'amount'},
      {text: "Discount Amount", value: "promo_price"},
      {text: "Tax", value: "tax"},
      {text: "Total Amount", value: "total_amount"},
      {text: "Payment Type", value: "payment_type"},
    ],
  }),
  computed: {
      dateRangeText () {
        return this.dates.join(' ~ ')
      },
    },
  methods: {
    viewDetails(item) {
      this.$router.push({name: 'Member Profile Overview', params: {member: item}});
    },
    hasActiveSub(item) {
      if (item.subscription === null) {
        return false;
      } else {
        return item.subscription.is_active
      }
    },
    downloadCsv() {
      // Replace this with your actual CSV data
      const csvData = this.members.map(item => ({
        "User ID": item.trainer.id,
        "Transaction Date & Time": this.covert_localtime(item.created_at),
        "Start Date & Time": item.booking ? this.covert_localtime(item.booking.start_time) : "",
        "End Date & Time": item.booking ? this.covert_localtime(item.booking.end_time) : "",
        "Hours": item.booking ? this.getDifferenceInHours(item.booking.end_time,item.booking.start_time)  : "",
        "Name": item.trainer.name,
        "Email": item.trainer.email,
        "Phone": item.trainer.phone,
        "Facility": item.gym.gym_name,
        "Service": item.service ? item.service.name : "",
        "Discount Type": item.promo_code,
        "Amount": item.amount,
        "Discount Amount": item.promo_price,
        "Tax": item.tax,
        "Total Amount": item.total_amount,
        "Payment Type": item.payment_type,
      }));;

      const csv = Papa.unparse(csvData);

      const blob = new Blob([csv], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);

      // Create a temporary anchor element and trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = `${Date.now()}SingleExclusiveGymSummery.csv`;
      document.body.appendChild(a);
      a.click();

      // Clean up
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    },
    ValidDate(item) {
      if (item.subscription === null) {
        return 'N/A';
      } else {

        var options = { 
          year: 'numeric', 
          month: 'short', 
          day: 'numeric' 
        };

        if(item.subscription.is_active){
          const currentDate = new Date();
          const newDate = new Date(item.subscription.valid_till);
          const differenceMs = newDate - currentDate;
          const daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
          return `${newDate.toLocaleDateString('en-GB', options)} (${daysDifference} days left)`;
        }else{
          return 'Expired'
        }
      }
    },
    statusChange(id, status) {
        authClient.post('/status/change',{
            status: status,
            tableId: 1,
            resultId: id
          }).then((response) => {
          let data = response.data[0]
          if(data.status){
            this.$toast.success({
              title: 'Ok',
              message: data.message,
          });
          this.getMembers()
          this.getCounts()
          }else{
            this.loading = false;
            this.$toast.error({
              title: 'error',
              message: data.message,
          });
          }
          }).catch((error) => {
            console.log(error);
          });
      },
    getMembers(type=0) {
      this.loading = true;
      authClient.post('/exclusive-service/summery',{
        'dateRange':this.dates,
        'gym_id' : this.gym_id,
        'service_id' : this.service_id,
        'search' : this.search,
        'type' : type,
      }, {responseType:  type == 0? '':'blob'}).then(response => {

        if(type == '1'){

          const href = window.URL.createObjectURL(response.data);

          const anchorElement = document.createElement('a');

          anchorElement.href = href;
          anchorElement.download = `${Date.now()}SingleExclusiveGymSummery.pdf`;
          document.body.appendChild(anchorElement);
          anchorElement.click();

          document.body.removeChild(anchorElement);
          window.URL.revokeObjectURL(href);

        }else{
          this.members = response.data;
         
        }
        this.loading = false;
      });
    },
    getCounts() {
      this.loading = true;
      authClient.post('/exclusive-service/summery/counts', {gym_id:this.gym_id}).then(response => {
        this.Total_amount = response.data.total;
        this.loading = false;
        console.log('NEW');
        console.log(response.data);
      }).catch(error => {
        console.log(error);
        this.loading = false;
      });
    }, getServices() {
      this.loading = true;
      authClient.get(`/fitness/exclusive-gyms/service/${this.gym_id}`).then(response => {
        this.services = response.data;
        this.loading = false;
        console.log('NEW');
        console.log(response.data);
      }).catch(error => {
        console.log(error);
        this.loading = false;
      });
    },
  },
  mounted() {
    this.getServices();
    this.getMembers();
    this.getCounts()
  }
}
</script>

<style scoped>

</style>
